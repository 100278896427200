import Head from 'next/head';

export default function CategoryContentTags() {
  return (
    <Head>
      <title>Bulas online de medicamentos no Whitebook</title>
      <meta
        name="description"
        content="Pesquise o bulário completo de medicamentos com nome comercial, princípio ativo, mecanismo de ação, via de administração, indicações de uso, ajustes e cuidados."
      />
      <meta name="robots" content="index, follow, max-image-preview:large, max-video-preview:-1, max-snippet:-1" />
    </Head>
  );
}
